import classNames from 'classnames/bind';
import React from 'react';

import { ListBullet } from './ListBullet/ListBullet';
import type { BulletShape, BulletColor, BulletType, BulletSize } from './ListBullet/ListBullet';
import { Icon } from '@components/Icon/Icon';
import type { IProps as TextProps } from '@components/Text/Text';
import { Text } from '@components/Text/Text';

import styles from './List.module.scss';
import CheckIcon from '@icons/check.svg';
interface Props {
  type?: 'ul' | 'ol';
  bullet?:
    | { size: BulletSize; shape: BulletShape; type: BulletType; color: BulletColor }
    | 'check'
    | JSX.Element;
  items: readonly (string | JSX.Element)[];
  size?: TextProps['size'];
  color?: TextProps['color'];
  opacity?: TextProps['opacity'];
  gap?: number;
  padding?: number;
  line?: 'none' | 'light' | 'dark';
  ulClassName?: string;
  liClassName?: string;
}

const cx = classNames.bind(styles);

export const List: React.FC<Props> = ({
  type: Tag = 'ul',
  bullet = { size: 'auto', shape: 'circle', type: 'filled', color: 'black' } as const,
  items,
  size = 'inherit',
  color = 'inherit',
  opacity = 100,
  gap = 20,
  padding = 24,
  line = 'none',
  ulClassName,
  liClassName,
}) => {
  const finalBullet = React.useMemo(() => {
    if (Tag === 'ol') {
      return <span className={styles.counter} />;
    }

    if (bullet === 'check') {
      return (
        <Icon color="blue200" size={size === 'inherit' ? 12 : Math.floor(size * 0.75)}>
          <CheckIcon />
        </Icon>
      );
    }

    if ('type' in bullet && 'color' in bullet && 'shape' in bullet && 'size' in bullet) {
      return <ListBullet {...bullet} />;
    }

    return bullet;
  }, [Tag, bullet]);

  return (
    <Tag
      className={cx('list', ulClassName, { withSeparator: line !== 'none' && items.length > 1 })}
      style={{
        ['--padding' as any]: `${padding}px`,
        ['--gap' as any]: `${gap}px`,
        ['--line-offset' as any]: `${Math.round(gap / 2)}px`,
        ['--padding' as any]: `${padding}px`,
      }}
    >
      {items.map((item, index) => (
        <li
          key={typeof item === 'string' ? item : index}
          className={cx('item', liClassName, {
            withSeparator: line !== 'none' && index + 1 !== items.length,
            [`separator-${line}`]: line !== 'none',
          })}
        >
          <Text className={liClassName} color={color} opacity={opacity} size={size} tag="div">
            <div className={styles.bulletWrapper}>{finalBullet}</div>
            {item}
          </Text>
        </li>
      ))}
    </Tag>
  );
};
